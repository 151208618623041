import React from 'react'

import Image from './Image'

const ContactInfo = ({ contact }) => (
  <div className="flex half">
    <div className="map">
      <a
        href="https://www.google.com/maps/place/3%2F91+W+Burleigh+Rd,+Burleigh+Heads+QLD+4220/data=!4m2!3m1!1s0x6b91039f6103fdd3:0x7360fd8354d4ee27?sa=X&ved=2ahUKEwifobOY86XyAhWMXisKHdK5AwMQ8gF6BAgREAE"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          className="cover"
          src="/images/map-4.jpg"
          alt="Find The Edit Suite"
        />
      </a>
    </div>
    {!!contact && (
      <div>
        {!!contact.address && (
          <span>
            <h5>Office</h5>
            <p>{contact.address}</p>
            <br />
          </span>
        )}

        {!!contact.phone && (
          <span>
            <h5>Phone</h5>
            <a href={`tel:${contact.phone}`}>
              <p>{contact.phone}</p>
            </a>
            <br />
          </span>
        )}

        {!!contact.email && (
          <span>
            <h5>Email</h5>
            <a href={`mailto:${contact.email}`}>
              <p>{contact.email}</p>
            </a>
          </span>
        )}
      </div>
    )}
  </div>
)

export default ContactInfo
